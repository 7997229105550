.wrapper
	position: fixed
	display: flex
	z-index: 2
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	background: #ba347b

	.main
		flex: 1

		.inner
			padding: 120px 50px 50px
			color: white

			//h2

			.menu
				margin: 50px -50px 0 0
				background: rgba(255,255,255,0.1)

				> div
					display: block
					margin: 0 0 10px
					padding: 20px
					color: white
					font-size: 22px
					transition: 0.5s
					cursor: pointer

					&:hover
						background: rgba(255,255,255,0.2)

					&.active
						background: white
						color: #ba347b

	.infoWrapper
		position: relative
		flex: 1

		.info
			position: absolute
			width: 100%
			height: 100%

			.cover
				position: relative
				top: 30px
				z-index: 3
				width: calc(100% - 30px)
				height: calc(100% - 60px)
				background: black
				border-radius: 10px
				overflow: hidden

				img
					width: 100%
					height: 100%
					opacity: 0.5
					object-fit: cover

			.textWrapper
				position: absolute
				z-index: 1000
				top: 50%
				left: 0
				width: 100%
				color: white
				font-size: 2em
				transform: translate3d(0, -50%, 0)

				.text
					padding: 50px

			&:global(.enter)

				.cover
					z-index: 2
					opacity: 0

				.textWrapper

					.text
						transform: translate3d(0,100px,0)
						opacity: 0

			&:global(.enter-active)

				.cover
					opacity: 1
					transition: 0.5s

				.textWrapper

					.text
						opacity: 1
						transform: translate3d(0,0,0)
						transition: 0.5s

			&:global(.exit)

				.cover
					opacity: 1

				.textWrapper

					.text
						transform: translate3d(0,0,0)
						opacity: 1

			&:global(.exit-active)

				.cover
					opacity: 0
					transition: 0.5s

				.textWrapper

					.text
						opacity: 0
						transform: translate3d(0,-100px,0)
						transition: 0.5s

@media all and (max-width: 760px)

	.wrapper
		flex-direction: column

		.main
			flex: none

			.inner
				padding: 100px 30px 50px

				.menu
					display: none

		.infoWrapper

			.info

				.cover
					top: 0
					width: 100%
					height: 100%
					border-radius: 0

				.textWrapper
					font-size: 1em

					.text
						padding: 0 30px
