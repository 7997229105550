.wrapper
	position: relative
	//background: #11939e
	//background: #000
	background: url('../images/pexels-jonathan-borba-4431922.jpg')
	background-size: cover
	padding: 100px calc((100% - 1000px) / 2)
	height: calc(100vh - 200px)
	color: white
	font-size: 50px
	font-weight: 100

	.bg
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: linear-gradient(to bottom right, rgba(50,50,50,0.1), rgba(30, 30, 30, 0.55))

.content
	position: absolute
	top: 50%
	left: 50%
	width: 70%
	font-size: 30px
	transform: translate(-50%, -50%)

	h2
		font-size: 50px

@media all and (max-width: 760px)

	.content
		left: 30px
		width: calc(100% - 60px)
		font-size: 20px
		transform: translate3d(0, -50%, 0)

		h2
			font-size: 30px
