.wrapper
	height: 100vh
	background: #eee

	.introVideo
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		//transform: translate(-50%, -50%)

		video
			width: 100%
			height: 100%
			object-fit: cover

		.shade
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			background: #222
			//background: linear-gradient(to bottom right, rgba(100,100,100,1), rgba(30,30,30,1))
			opacity: 0
			transition: 0.3s
			pointer-events: none

			img
				width: 100%
				height: 100%
				object-fit: cover

			&.active
				opacity: 1

		.toggle
			position: absolute
			z-index: 100
			bottom: 240px
			left: 50%
			width: 50px
			height: 50px
			border: solid 5px white
			border-radius: 50%
			transform: translate3d(-50%, 0, 0)
			cursor: pointer

			.text
				position: absolute
				bottom: calc(100% + 30px)
				left: -125px
				width: 300px
				color: white
				font-size: 12px
				text-align: center
				text-transform: uppercase

			img
				position: absolute
				top: 50%
				left: 53%
				width: 30%
				transform: translate3d(-50%, -50%, 0)
				opacity: 1
				transition: 0.3s

				&:nth-child(2)
					left: 50%
					opacity: 0

			&.active

				img
					opacity: 0

					&:nth-child(2)
						opacity: 1

		.subtitles
			position: absolute
			z-index: 2
			top: 50%
			left: 25%
			width: 50%
			color: white
			font-size: 22px
			font-weight: bold
			text-align: center
			text-shadow: 0 5px 10px rgba(0,0,0,0.9)
			transform: translate(0, -50%)
			transition: 0.5s
			opacity: 0

			&.active
				opacity: 1

			.text
				position: absolute
				width: 100%
				transform: translate3d(0,0,0)

				&:global(.enter)
					transform: translate3d(0,100px,0)
					opacity: 0

				&:global(.enter-active)
					transform: translate3d(0,0,0)
					opacity: 1
					transition: 0.8s

				&:global(.exit)
					transform: translate3d(0,0,0)
					opacity: 1

				&:global(.exit-active)
					transform: translate3d(0,-100px,0)
					opacity: 0
					transition: 0.8s

		@media all and (max-width: 760px)

			.toggle
				bottom: 180px
				width: 40px
				height: 40px

			.subtitles
				top: calc(50% - 50px)
				left: 20px
				width: calc(100% - 40px)
				font-size: 16px






	.controls
		display: flex
		position: fixed
		bottom: 0
		left: 0
		width: 50%
		height: 60px
		background: rgba(0,0,0,0.9)
		border-radius: 0 10px 0 0
		color: white

		.button
			margin: 0 20px 0 0
			width: 60px
			height: 100%
			text-align: center

		.bar
			position: relative
			flex: 1
			margin: 0 20px

			&::after
				content: ''
				position: absolute
				top: calc(50% - 0.5px)
				width: 100%
				border-bottom: solid 1px rgba(255,255,255,0.3)

			.dot
				position: absolute
				top: calc(50% - 7.5px)
				width: 15px
				height: 15px
				border-radius: 50%
				background: #ddd
				cursor: pointer
