$normalTransitionSpeed: 2s
$fastTransitionSpeed: 0.5s

html, body
	margin: 0
	padding: 0
	overscroll-behavior-y: none

	&::-webkit-scrollbar
		display: none

body, input, textarea
	font-family: 'Europa', 'europa', Helvetica, sans-serif
	line-height: 1.1

html
	background: #333

section
	min-height: 100vh
	border-bottom: solid 1px #ccc

	&.fixedHeight
		position: relative
		height: 100vh

		&:first-of-type, &:last-of-type
			height: 150vh

a
	color: #069
	text-decoration: none
	cursor: pointer



.tracker
	position: absolute
	z-index: 20000
	top: calc(50% - 25px)
	right: 0
	width: 50px
	height: 10px
	//background: red


.page
	position: fixed
	z-index: 2
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	background: white

	&.enter
		z-index: 3
		transform: translate3d(0, 100%, 0)

		&.up
			transform: translate3d(0, -100%, 0)

	&.enter-active, &.up.enter-active
		z-index: 3
		transform: translate3d(0, 0, 0)
		transition: $normalTransitionSpeed

		&.fast
			transition-duration: $fastTransitionSpeed

	&.enter-done
		z-index: 3
		transform: translate3d(0, 0, 0)

	&.exit
		transform: translate3d(0, 0, 0)

	&.exit-active
		transform: translate3d(0, -50%, 0)
		transition: $normalTransitionSpeed

		&.up
			transform: translate3d(0, 50%, 0)

		&.fast
			transition-duration: $fastTransitionSpeed

	&.exit-done
		transform: translate3d(0, -50%, 0)

		&.up
			transform: translate3d(0, 50%, 0)
