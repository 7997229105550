.countdown
	position: absolute
	z-index: 2
	left: calc(50% - 200px)
	bottom: 80px
	width: 400px
	//background: white
	border-radius: 5px
	font-size: 24px
	text-align: center

	.text
		margin-bottom: 25px
		color: white
		font-weight: bold
		text-shadow: 0 5px 10px black

	.tiles
		display: flex
		gap: 15px

		> div
			flex: 1
			padding: 10px 0
			background: white
			border-radius: 5px
			font-weight: bold

			label
				display: block
				margin-top: 5px
				font-size: 15px
				font-weight: normal

@media all and (max-width: 760px)

	.countdown
		bottom: 60px
		left: 30px
		width: calc(100% - 60px)
		font-size: 20px

		.text
			margin-bottom: 15px

		.tiles
			gap: 10px

			> div

				label
					font-size: 12px
