.sideNav
	position: fixed
	z-index: 5
	top: 50%
	left: 20px
	transform: translateY(-50%)

	.dot
		position: relative
		margin: 15px 0
		width: 12px
		height: 12px
		//background: #333
		border: solid 2px #333
		border-radius: 50%
		//transition: 0.3s
		overflow: hidden

		.inner
			position: absolute
			top: 12px
			left: 0
			width: 100%
			height: 100%
			background: #333
			opacity: 0
			//transform: scale(0)
			transition: 0.8s

		&.active .inner
			top: 0
			opacity: 1
			transform: scale(1)

	&.dark

		.dot
			border-color: white

			.inner
				background: white
