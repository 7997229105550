.wrapper
	position: fixed
	z-index: 2
	padding: 200px 50px 100px
	width: calc(100% - 100px)
	min-height: calc(100vh - 300px)
	background: #4e4a4a

	.text
		position: absolute
		top: 50%
		left: calc(50% - 300px)
		width: 600px
		text-align: center
		color: white
		font-size: 20px
		line-height: 1.5em
		transform: translate3d(0,-50%,0)

		a
			display: inline-block
			margin-top: 50px
			padding: 0 20px
			background: white
			border-radius: 18px
			color: black
			font-weight: bold
			line-height: 36px

		form
			position: relative
			margin-top: 40px

			.message
				position: absolute
				top: -5px
				left: 5%
				width: 90%
				padding: 50px 0
				background: #398cc7
				box-shadow: 0 5px 10px #333
				color: white
				opacity: 0
				transition: 0.5s

				&.active
					opacity: 1

			input, textarea
				display: block
				margin-bottom: 10px
				padding: 20px
				border: none
				width: calc(100% - 40px)
				text-align: center
				-webkit-appearance: none

				&[type="submit"]
					margin-top: 30px
					width: 100%
					background: black
					border: none
					color: white

			textarea
				height: 100px




	//.links
	//	margin-bottom: 100px
	//	text-align: center
	//
	//	a
	//		display: inline-block
	//		margin: 0 10px
	//		color: white
	//		text-transform: uppercase
	//
	//.social
	//	text-align: center
	//
	//	a
	//		display: inline-block
	//		margin: 0 10px
	//		width: 30px
	//		height: 30px
	//		background: #ccc
	//		border-radius: 3px


@media all and (max-width: 760px)

	.wrapper

		.text
			left: 30px
			width: calc(100% - 60px)
			font-size: 16px

			form
				//margin-top: 20px

				input, textarea
					padding: 10px
					width: calc(100% - 20px)

					&[type="submit"]
						padding: 15px

				textarea
					height: 80px
