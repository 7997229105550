.wrapper

	header
		position: fixed
		z-index: 100
		top: 0
		left: 0
		width: 100%
		height: 100px
		//background: white

	.logo
		position: absolute
		top: 20px
		left: 30px

		img
			height: 50px

	.button
		position: absolute
		z-index: 1
		top: 25px
		left: calc(100% - 150px)
		height: 40px
		width: 100px
		background: white
		border-radius: 20px
		transition: 0.5s cubic-bezier(0.9,0,0.1,1)

		&.dark
			background: black


	nav
		position: absolute
		z-index: 2
		top: 0
		left: 250px

		> div
			display: inline-block
			margin: 0
			padding: 0 30px
			font-size: 14px
			line-height: 90px
			text-transform: uppercase
			letter-spacing: 0.2em
			transition: 0.5s
			cursor: pointer

			//&:hover
				//background: #333
				//color: white

			&.active
				//background: #1bac58
				color: white

		&:global(.dark)

			> div
				color: white

				&.active
					color: black

@media all and (max-width: 760px)

	.wrapper

		.button
			display: none

		.menuBtn
			position: absolute
			z-index: 100
			top: 30px
			right: 30px
			width: 40px

			span
				display: block
				margin-bottom: 7px
				height: 4px
				background: white

			&.light span
				background: black

			&.light.expanded span
				background: white

		nav
			left: auto
			right: 0
			padding-top: 100px
			width: 80%
			height: 100vh
			background: black
			transform: translate3d(100%, 0, 0)
			transition: 0.5s cubic-bezier(0.8, 0, 0.2, 1)

			&.expanded
				transform: translate3d(0, 0, 0)

			> div
				display: block
				line-height: 50px
				color: white
				opacity: 0.6

				&.active
					color: white
					opacity: 1

			&:global(.dark)

				> div
					color: white

					&.active
						color: white
						opacity: 1
