.wrapper
	padding: 100px

	.content
		position: absolute
		top: 50%
		left: 50%
		width: 80%
		transform: translate(-50%, -50%)

		.points
			display: flex
			flex-wrap: wrap
			justify-content: space-between
			//height: 500px
			text-align: left

			.point
				display: block
				width: calc((100% - 90px) / 4)
				//height: 500px
				//background: blue
				//mask-image: url('../images/mask.png')
				//mask-size: cover

				//.bg
				//	position: relative
				//	width: 100%
				//	height: 100%
				//	background: url(#icon)
				//	animation: flow 1s infinite linear
					//animation: flash 1s infinite alternate-reverse

				strong
					color: #3c811f

				.icon
					position: relative
					z-index: 1
					margin: 0 auto 40px
					padding-top: 80%
					width: 80%
					mask-image: url('../images/mask-1.png')
					mask-size: cover
					//border: solid 20px black
					//border-radius: 50%

					.bg
						position: absolute
						z-index: 0
						top: 0
						left: 0
						width: 200%
						height: 300%
						background: url('../images/points-bg.jpg')
						background-size: 100% 100%
						animation: flow 12s infinite linear

					.number
						position: absolute
						top: 50%
						left: 50%
						font-size: 100px
						font-weight: bold
						transform: translate3d(-50%, -50%, 0)

				&:nth-child(2)

					.icon
						mask-image: url('../images/mask-2.png')

						.bg
							left: -33.333%
							animation-duration: 10s

					strong
						color: #114cbd

				&:nth-child(3)

					.icon
						mask-image: url('../images/mask-3.png')

						.bg
							left: -66.666%
							animation-duration: 15s

					strong
						color: #d73050

				&:nth-child(4)

					.icon
						mask-image: url('../images/mask-4.png')

						.bg
							left: -100%
							animation-duration: 20s

					strong
						color: #da7e06

	@media all and (max-width: 760px)

		.content
			left: 90px
			width: calc(100% - 120px)
			transform: translate(0, -50%)

			.points
				display: block

				.point
					position: relative
					margin: 0 0 15px
					width: auto

					.icon
						position: absolute
						top: -10px
						left: -100px
						margin: 0
						padding: 0
						width: 85px
						height: 85px


						.number
							font-size: 30px

@keyframes rotate
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)

@keyframes flow
	from
		transform: translate3d(0,0,0)
	to
		transform: translate3d(0,-66.666%,0)

@keyframes flash
	from
		background: blue
	to
		background: red
